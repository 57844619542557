.menu {
  height: 100%;
  width: 100%;
  background-color: #000;
  overflow-y: auto;
  overflow-x: hidden;
  color: #fff;
}
.menu_not_found {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.page_not_found {
  font-size: 4rem;
  font-weight: 500;
  color: #fff;
}
.not_found {
  font-size: 5rem;
  font-weight: 500;
  color: #fff;
}

/*  Spinner   */
.spinner_container {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  background: #000;
}
.spinner_wrapper {
  position: relative;
  width: 25%;
}
.spinner {
  width: 100%;
}
.spinner_inner_container {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: transparent !important;
  justify-content: center;
}
.spinner_inner {
  width: 80%;
  position: relative;
  background-color: transparent !important;
}

.spinner_inner .rc-progress-circle-path {
  stroke: #000 !important;
  fill-opacity: 1 !important;
}
.inner_path_completed .rc-progress-circle-path {
  stroke-dasharray: 304.734px, 307.734 !important;
  stroke-dashoffset: 305.187 !important;
  /* stroke: #fff !important; */
  /* stroke-width: 0.1 !important; */
}

.inner_path_completed .rc-progress-circle-trail {
  fill-opacity: 1 !important;
}
.spinner_percent {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
  color: #fff;
  font-size: 8rem;
  font-weight: bold;
}

/*  Mneu Popup  */

@keyframes popup_list_anim {
  0% {
    height: 0;
  }
  50% {
    height: 70%;
  }
  100% {
    height: 100%;
  }
}

.popup_container {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 6;
  background: #1e1e1e;
  animation: popup_list_anim 0.3s linear alternate;
}
.popup {
  width: 100%;
  height: 100%;
  overflow: hidden;
  /* display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column; */
  padding-block: 5%;
  text-align: center;
}
.popup_select {
  color: var(--white);
  font-size: 2.4rem;
  font-weight: 600;
  margin-bottom: 4rem;
  /* text-align: center; */
}
.popup_list {
  padding-block: 3% 7%;
  width: 100%;
  height: 70%;
  overflow-y: auto;
}
.popup_list::-webkit-scrollbar {
  display: none;
}
.popup_name {
  font-size: 2.4rem;
  cursor: pointer;
  background: transparent;
  color: #fff;
  border-top: 1px solid var(--border-gray-light);
  border-bottom: 0;
  border-left: 0;
  border-right: 0;
  padding-block: 2.7rem;
  width: 100%;
  text-align: center;
  display: inline-block;
}
.popup_name:last-of-type {
  border-bottom: 1px solid var(--border-gray-light);
}
.popup_btn {
  border-radius: 50%;
  margin-top: 6%;
  cursor: pointer;
  background-color: transparent;
  border: none;
  /* border: 1px solid var(--text-gray-1); */
}

.popup_btn_icon {
  width: 100%;
  height: 100%;
  color: var(--bg-gray-1);
}

/*   Menu Content   */
.menu_content {
  color: #fff;
  width: 100%;
  height: 100%;
  padding-left: 6%;
}

@media screen and (max-width: 500px) {
  .menu_content {
    padding-left: 6%;
    padding-right: 6%;
  }
}
